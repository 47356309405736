<nav class="navbar navbar-dark {{ bgColor }} mb-3 text-center sticky-top" *ngIf="Title!=''">
  <a
    [routerLink]="['/']"
    class="
      navbar-brand
      mb-0
      mx-auto
      d-flex
      justify-content-center
      align-items-center
    "
    ><span *ngIf="IsBaba"
      ><img
        src="assets/img/sssbk.png"
        width="18.13px"
        height="50px"
        style="
          -webkit-filter: drop-shadow(0px 1px 1px #222);
          filter: drop-shadow(0px 1px 1px #222);
        "
      />&nbsp;</span
    ><img src="assets/img/gls-sm.png" width="50px" height="50px" />&nbsp;<span
      class="h4 m-0 px-1 {{ titlColor }}"
      ><span class="d-none d-md-inline">{{ Title }}</span
      ><span
        class="d-inline-block d-md-none text-wrap"
        style="font-size: 16px"
        >{{ Mobtitle }}</span
      ></span
    >&nbsp;</a
  >
</nav>
