<app-header class="d-blockposition-static"></app-header>
<main class="py-4 main-container d-flex">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main> 
<app-footer></app-footer>

<div class="modal fade show" tabindex="-1" id="thankyoumodal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div>
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
        </div>

        <h5>
          Thank you for taking the time to submit your volunteer application.
        </h5>
        <h5>We will get back to you soon.</h5>
        <p>Team SSBK</p>
      </div>
    </div>
  </div>
</div>
 
<div class="modal fade show" tabindex="-1" id="thankyoureceipt">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h5 class="modal-title"></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body   Receiptmessage" >
         <div id="Receiptmessage" class="mb-3">

         </div>
         <div class="text-end">
          <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy to clipboard" id="copyrepmsg" (click)="copyMsg()">Copy</button>
         </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade show"
  id="thankyouparticipantsmodal"
  tabindex="-1"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h5 class="modal-title"></h5>
        <button
          aria-label="btn-close"
          class="close"
          data-bs-dismiss="modal"
          type="button"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div>
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span
              ><span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
        </div>
        <h5>
          Thank you for taking the time to submit your Workshop / deekshe
          Enrollment application.
        </h5>
        <h5>We will get back to you soon.</h5>
        <p>Guruji Team</p>
        <div class="my-3 text-center">
          <button
            aria-label="Close"
            class="btn btn-primary"
            data-dismiss="modal"
            type="button"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade show" id="feedbackformmodal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h5 class="modal-title"></h5>
        <button
          aria-label="Close"
          class="btn-close"
          data-bs-dismiss="modal"
          type="button"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div>
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span
              ><span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
        </div>
        <h5>
          Thank you for taking the time to submit your Workshop / deekshe
          Feedback application.
        </h5>
        <p>Guruji Team</p>
        <div class="my-3 text-center">
          <button
            aria-label="Close"
            class="btn btn-primary"
            data-dismiss="modal"
            type="button"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
