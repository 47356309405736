import { Component, OnInit, ElementRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Event, Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {AppConfigService} from '../../_services/app-config.service';
import { countries } from 'src/app/store/country-data-store';
declare var $: any;
declare var bootstrap:any;

@Component({
  selector: 'app-agnihotra',
  templateUrl: './agnihotra.component.html',
  styleUrls: ['./agnihotra.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgnihotraComponent implements OnInit {

  @ViewChild('paybtn', { static: false }) paybtn: ElementRef;
  userform: FormGroup;
  submitted: boolean = false;
  posting: boolean = false;
  regionKey: string = '';
  area: string = "";
  district: string = "";
  state: string = "";
  imageSrc: string = "";
  btnName = "Submit";
  selectedMobileCallingCode:string="91";
  selectedWhatsappCallingCode:string="91";
  selectedEmergencyCallingCode:string="91";
  mobileNummap={};
  isPaymentLoading=false;
  stepForm:any=1;
  @ViewChild('TabHolderdiv', { static: false }) TabHolderdiv: ElementRef;
  Title = "Sri Shirdi Sai Biksha Kendra (R) || ಶ್ರೀ ಶಿರಡಿ ಸಾಯಿ ಭಿಕ್ಷಾ ಕೇಂದ್ರ (ರಿ)";
  pageTitle='';
  docContent:""="";
  tabContents:Array<object[]>=[];
  pageTitle_Kn='';
  docContent_Kn:'';
  public countries:any = countries

  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  educationalDetails!: FormGroup;
  experienceDetails!: FormGroup;
 
  stepValid={ 
    1 : true,
    2: false,
    3 : false,
    4:false,
    5:false
  }
  stepControls={
    1:null,
    2:null,
    3:null,
    4:null,
    5:null
  };
  step = 1;

  isImageupload = false;
  pdfSource = window.location.origin + "/assets/workshop-terms.pdf";
  apiurl = AppConfigService.settings.apiServer.apiurl;


  PageInitData=AppConfigService.settings.pagedata;
  dropdownList = [];
  selectedItems = [];
  PurposeSelected = [];
  ActiveLocation:object={};

  workshoplist = [];
  workshopSelected = [];
  PaymentUrl = "";

  dropdownSettings: IDropdownSettings;


  constructor(private formBuilder: FormBuilder, private http: HttpClient, private el: ElementRef, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void { 
    this.dropdownList = AppConfigService.settings.AgnihotraPurpose; 
    this.workshoplist = AppConfigService.settings.AgnihotraWorkshopList;
    if(this.PageInitData["agnihotra-registration"]){
      var Cdata=this.PageInitData["agnihotra-registration"];
      if(Cdata["docTitle"]){
        this.pageTitle=Cdata["docTitle"];
      }
       if(Cdata["docContent"]){
        this.docContent=Cdata["docContent"];
      } 
      if(Cdata["docTitle_Kn"]){
        
        this.pageTitle_Kn=Cdata["docTitle_Kn"];
      }

      if(Cdata["docContent_Kn"]){
        this.docContent_Kn=Cdata["docContent_Kn"];
      }
      if(Cdata["TabContent"]){
        this.tabContents=Cdata["TabContent"];
        if(Cdata["TabContent"].length!=0){
         
        };
       
      }
      if(Cdata["TabContent"]){ 
        if(Cdata["TabContent"].length!=0){
         this.ActiveLocation=Cdata["TabContent"][0];
        };
       
      }
    }
    
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      selectAllText: '',
      unSelectAllText: '',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
    let self = this;
   

    this.personalDetails = this.formBuilder.group({
      "fullName": ['', [Validators.required]], 
      "PhotoUpload": ['', [Validators.required]],
      "mobileNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "whatsappNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]], 
      "emergencyContact": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
  });

  this.addressDetails = this.formBuilder.group({
    "address": ['', [Validators.required]],
    "pincode": ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
    "emailId": ['', [Validators.required, 	Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,6}$")]],
    "dob": ['', [Validators.required]],
    "bloodGroup": ['', [Validators.required]],
    "gender": ['', [Validators.required]],
});

this.educationalDetails = this.formBuilder.group({
  "qualifications": '',
  "Occupation": ['', [Validators.required]],
 
});

this.experienceDetails=this.formBuilder.group({ 
  "previousExperience": '',
  "source": ['', [Validators.required]],
  "specificIssue": ['', [Validators.required]],
});

this.stepControls[2]= this.personalDetails;
 this.stepControls[3]= this.addressDetails;
 this.stepControls[4]= this.educationalDetails;
 this.stepControls[5]= this.experienceDetails;
 this.mobileNummap["mobileNumber"]=this.selectedMobileCallingCode;
 this.mobileNummap["whatsappNumber"]=this.selectedWhatsappCallingCode;
 this.mobileNummap["emergencyContact"]=this.selectedEmergencyCallingCode; 

    window.addEventListener('tabclicked', (ev)=>{
      
if(ev["detail"]){
  this.ActiveLocation=ev["detail"];
}
    });

 this.addressDetails.get("pincode").valueChanges.subscribe(selectedValue => {
      this.onKeyUp(this.addressD.pincode);
    })

    /*var myModal = new bootstrap.Modal(document.getElementById('paymentmodal'), {backdrop:'static',keyboard:false,focus:false});
    myModal.show()*/

  }
  seoString(str){
    return str.replace(/\s+/g, '-').toLowerCase()
  }
 



  changeterm(type) {
    if (type == "en") {

      this.pdfSource = window.location.origin + "/assets/terms_en.pdf";
    } else {
      this.pdfSource = window.location.origin + "/assets/terms_ka.pdf";
    }

  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = () => { 
        this.imageSrc = reader.result as string;
        this.isImageupload = true;

        let formData = new FormData();
        formData.append("file", file);

        this.http.post(this.apiurl + 'volunteers/photos', formData, { responseType: 'text' })
          .subscribe(res => {
            this.isImageupload = false;
            this.imageSrc = res;

          })

      };

    }
  }
  onKeyUp(event) {

    if (!event.invalid) {
      if (event.value.length == 6) {
        this.http.get<any>(this.apiurl + "pincodes/" + event.value).subscribe(data => {
          this.regionKey = data.publicKey;
          this.area = data.area;
          this.district = data.district;
          this.state = data.state;

        });
      };
    }

  }
  

  get personal() { return this.personalDetails.controls; }
  get education() { return this.educationalDetails.controls; }
  get addressD() { return this.addressDetails.controls; }
  get experience() { return this.experienceDetails.controls; }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    ); 
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 130;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
  openpayUrl() {
    var myModal = new bootstrap.Modal(document.getElementById('paymentmodal'), {backdrop:'static',keyboard:false,focus:false});
    myModal.hide();
    window.open(this.PaymentUrl).focus();
  }
  onSubmit() {
    this.submitted = true; 
     
    this.stepValid[this.stepForm]=true;
    var MobileField=Object.keys(this.mobileNummap);
    if (!this.experienceDetails.invalid) { 
      var postpara = {};
      var formData: any = new FormData();
   Object.values( this.stepControls).forEach(forms => {
    if(forms){
      Object.keys(forms.controls).forEach(key => {
      
        if (key != "PhotoUpload") {
           if (key == "dOB") {
            postpara[key] = new Date(forms.controls[key].value);
          } else {
            if(MobileField.includes(key)){
              var CallingCode=this.mobileNummap[key];
              postpara[key] = CallingCode+forms.controls[key].value;
            }else{
              postpara[key] = forms.controls[key].value;
            }
          
          }
   
        }
      });
    }
    
    });
    
      postpara["regionKey"] = this.regionKey;
      postpara["image"] = this.imageSrc;
      if (this.PurposeSelected.length != 0) {
        var purpstring = "";
        for (let pu in this.PurposeSelected)  purpstring += this.PurposeSelected[pu]["item_text"];
        postpara["purpose"] = purpstring;

      }

      if (this.workshopSelected.length != 0) {
        var WorkShopList = "";
        for (let pu in this.workshopSelected)  WorkShopList += this.workshopSelected[pu]["item_text"];
        postpara["previousWorkshops"] = WorkShopList;

      }

if(this.ActiveLocation["PublicKey"])postpara["EventLocation"]=this.ActiveLocation["PublicKey"];

      this.posting = true;
     
 this.http.post<any>(this.apiurl + "participants/", postpara).subscribe((data) => {
        this.posting = false;
        this.submitted = false;
        this.regionKey = "";
        this.area = "";
        this.district = "";
        this.state = "";
        this.imageSrc = "";
      

        if (data["fullName"] && data["emailId"] && data["mobileNumber"] && data["publicKey"]) {
          if(AppConfigService.settings.AllowPayment){
            this.isPaymentLoading=true;
            var payDetails = new FormData();
            payDetails.append("pk", data["publicKey"]);
            payDetails.append("username", data["fullName"]);
            payDetails.append("mobile", data["mobileNumber"]);
            payDetails.append("email", data["emailId"]);
            var Fromapi=AppConfigService.settings.apiServer.glsformapi;
            var myModal = new bootstrap.Modal(document.getElementById('paymentmodal'), {backdrop:'static',keyboard:false,focus:false});
            myModal.show();
            this.http.post<any>(Fromapi+"order/pay", payDetails).subscribe((pdata) => {
              if (pdata["longurl"]) {
                this.PaymentUrl = pdata["longurl"];
           
           this.isPaymentLoading=false;
           this.personalDetails.reset();
           this.addressDetails.reset();
           this.educationalDetails.reset();
           this.experienceDetails.reset();
           
          /* this.userform.reset();*/
              }
            },(error)=>{
              myModal.hide();
              console.error('error caught in component ' + error);
            });
          }else{
            this.personalDetails.reset();
           this.addressDetails.reset();
           this.educationalDetails.reset();
           this.experienceDetails.reset();
 
          }
         

        }
        


      },
        (error) => {                     
          console.error('error caught in component ' + error)
          this.posting = false;
          this.submitted = false;
        }); 

    } else {
      this.scrollToFirstInvalidControl();
    }

    return false;
  }

  onTabChanged(){

  }
  backStep(){
    if(this.stepForm!=1){
      this.stepForm=this.stepForm-1;
    }

  }

  nextStep(){
    if(this.stepForm<Object.keys(this.stepControls).length){
      if(this.stepForm>1){
        var currentStepForm= this.stepControls[this.stepForm];
        this.stepValid[this.stepForm]=true;
        if(currentStepForm.invalid) return;
      }
     
      this.stepForm=this.stepForm+1;
      if(this.stepForm==3){
        let self=this;
        setTimeout(()=>{
          $('#dobpick').dropdownDatepicker({
            dropdownClass: "form-select",
            minYear: 1920,
            maxYear: 2015,
            submitFieldName: 'dob_dd',
            wrapperClass: "position-relative input-group",
            onChange: function (day, month, year) {
               
              if (month != null && day != null && year != null) {
      
                self.addressDetails.patchValue({ "dob": year + "-" + month + "-" + day });
              }
      
      
            }
          });
        },150);
     
      
      }
    }
  }
  validateNumField(control: AbstractControl): { [key: string]: any } {
    if (!control.value || control.value % this.stepForm !== 0) {
     
      return { errors: true };
  }else{
    
  }
  return null;
  }
  onItemSelect(event){

  }
  onItemDeSelect(event){

  }
  onWorkshopSelect(event){

  }
  onWorkshopDeSelect(event){

  }
}
