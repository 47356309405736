import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Event, Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppConfigService} from '../../_services/app-config.service';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  userform: FormGroup;
  submitted: boolean = false;
  posting: boolean = false;
  regionKey: string = '';
  area: string = "";
  district: string = "";
  state: string = "";
  imageSrc: string = "";

  btnName = "Submit";

  isImageupload = false;
  pdfSource = "/assets/terms_en.pdf";
  apiurl = AppConfigService.settings.apiServer.apiurl;
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private el: ElementRef, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    let self = this;
    this.userform = this.formBuilder.group({

      "fullName": ['', Validators.required],
      "address": ['', Validators.required],
      "pincode": ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      "PhotoUpload": ['', Validators.required],
      "mobileNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "whatsappNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "landlineNumber": ['', Validators.required],
      "emergencyContact": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "emailId": ['', [Validators.required, Validators.email]],
      "dob": [null, Validators.required],
      "doa": "2020-02-28",
      "doj": ["2020-10-01", Validators.required],
      "bloodGroup": ['', Validators.required],
      "gender": ['', Validators.required],
      "qualifications": '',
      "presentEmploymentDetails": ['', Validators.required],
      "previousExperience": ['', Validators.required],
      "whySsbk": ['', Validators.required],
      "technicalSkills": ['', Validators.required],
      "hobbies": ['', Validators.required],
      "extraCurricularActivites": ['', Validators.required],
      "presentRoleSsbk": ['', Validators.required],
      "aboutYourself": ['', Validators.required],



    });


    $('#dobpick').dropdownDatepicker({
      dropdownClass: "form-select",
      minYear: 1920,
      maxYear: 2015,
      submitFieldName: 'dob_dd',
      wrapperClass: "position-relative input-group",
      onChange: function (day, month, year) {
        if (month != null && day != null && year != null) {

          self.userform.patchValue({ "dob": year + "-" + month + "-" + day });
        }


      }
    });

    $('#doapicker').dropdownDatepicker({
      dropdownClass: "form-select",
      minYear: 1920,
      maxYear: new Date().getFullYear(),
      defaultDate: "",
      submitFieldName: 'doa_dd',
      wrapperClass: "position-relative input-group",
      onChange: function (day, month, year) {
        if (month != null && month != '' && day != null && day != '' && year != null && year != '') {

          self.userform.patchValue({ "doa": year + "-" + month + "-" + day });
        }

      }
    });

    $('#dojpicker').dropdownDatepicker({
      dropdownClass: "form-select",
      minYear: 1920,
      maxYear: new Date().getFullYear(),

      defaultDate: "",
      wrapperClass: "position-relative input-group",
      onChange: function (day, month, year) {
        if (month != null && month != '' && day != null && day != '' && year != null && year != '') {

          self.userform.patchValue({ "doj": year + "-" + month + "-" + day });
        }

      }
    });


    this.userform.get("pincode").valueChanges.subscribe(selectedValue => {
      this.onKeyUp(this.f.pincode);
    })

  }


  changeterm(type) {
    if (type == "en") {

      this.pdfSource =   "/assets/terms_en.pdf";
    } else {
      this.pdfSource =  "/assets/terms_ka.pdf";
    }

  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;
        this.isImageupload = true;

        let formData = new FormData();
        formData.append("file", file);

        this.http.post(this.apiurl + 'volunteers/photos', formData, { responseType: 'text' })
          .subscribe(res => {
            this.isImageupload = false;
            this.imageSrc = res;

          })

      };

    }
  }
  onKeyUp(event) {

    if (!event.invalid) {
      if (event.value.length == 6) {
        this.http.get<any>(this.apiurl + "pincodes/" + event.value).subscribe(data => {
          this.regionKey = data.publicKey;
          this.area = data.area;
          this.district = data.district;
          this.state = data.state;

        });
      };
    }

  }
  get f() {
    return this.userform.controls;
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 130;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
  onSubmit() {
    this.submitted = true;
    if (!this.userform.invalid) {

      var postpara = {};
      var formData: any = new FormData();
      Object.keys(this.userform.controls).forEach(key => {
        if (key != "PhotoUpload") {
          if (key == "dOB") {
            postpara[key] = new Date(this.f[key].value);
          } else {
            postpara[key] = this.f[key].value;
          }

          formData.append(key, this.f[key].value);
        }
      });
      postpara["regionKey"] = this.regionKey;
      postpara["image"] = this.imageSrc;


      this.posting = true;

      this.http.post<any>(this.apiurl + "volunteers/", postpara).subscribe((data) => {
        this.posting = false;
        this.submitted = false;
        this.regionKey = "";
        this.area = "";
        this.district = "";
        this.state = "";
        this.imageSrc = "";
        this.userform.reset();
        $('#thankyoumodal').modal('show');

      },
        (error) => {                              //Error callback
          console.error('error caught in component ' + error)
          this.posting = false;
          this.submitted = false;
        });




    } else {
      this.scrollToFirstInvalidControl();
    }

    return false;
  }

}
