import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppConfigService} from '../../../_services/app-config.service';

@Component({
  selector: 'app-agnihotra-thankyou',
  templateUrl: './agnihotra-thankyou.component.html',
  styleUrls: ['./agnihotra-thankyou.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgnihotraThankyouComponent implements OnInit {

  PaymentId: string = "";
  PaymentStatus: string = "";
  WasPaymentAllow:Boolean=AppConfigService.settings.AllowPayment;
  apiurl = AppConfigService.settings.apiServer.apiurl;
  fapi=AppConfigService.settings.apiServer.glsformapi;
  PaymentMessage: string = (AppConfigService.settings.AllowPayment)?AppConfigService.settings.WorkshopPaymentSuccessMsg:AppConfigService.settings.WorkshopWithOutPaymentSuccessMsg;
  PaymentTitle: string = "Your Payment was successful";
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) { }

  ngOnInit() {
    if(AppConfigService.settings.AllowPayment){
      if (this.route["queryParams"]) {
        this.route.queryParams.subscribe(para => { 
          if (para["payment_status"] && para["payment_request_id"] && para["payment_id"]) {
            this.PaymentStatus = para["payment_status"];
            if (para["payment_status"] != "Credit") {
              this.PaymentMessage = AppConfigService.settings.WorkshopPaymentFailMsg;
              this.PaymentTitle = "Payment Failed";
            } else {
              
             }  
            var detailsGet = new FormData();
            //this.PaymentId = para["payment_id"];
            detailsGet.append("payid", para["payment_id"]);
            detailsGet.append("reqid", para["payment_request_id"]);
            detailsGet.append("pk", para["pk"]);
            this.http.post<any>(this.fapi+"/order/paydetails", detailsGet).subscribe(detail => {
            
              this.PaymentId = detail["purpose"];
              if (detail["payment"]) {
  
              }
            })
  
  
          }
        });
      }
    }else{
      this.PaymentStatus="Credit";
      this.PaymentTitle="";
    }
   
  }
}
