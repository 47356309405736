import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router,Resolve, RouterStateSnapshot, UrlSegment, RoutesRecognized } from '@angular/router';
import {filter} from 'rxjs/operators';
import {map, mergeMap} from 'rxjs/internal/operators';
import { Observable, BehaviorSubject, of, concat, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutedataService {

    public currentroute={};
    private PLSubject = new BehaviorSubject<any | undefined>(undefined);
 public PLUpdate: Observable<any>;
    
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
       this.PLUpdate = this.PLSubject.asObservable();
router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
  this.resolveCrumbs(router.routerState.snapshot.root,event)
    
});
    
  }
    
     private resolveCrumbs(route: ActivatedRouteSnapshot,event: NavigationEnd) {
     const data = route.routeConfig && route.routeConfig.data;
        
          if (data) {
              this.currentroute={
                  eve:event ,
                  data:data
              }
              this.PLSubject.next( this.currentroute);
           
          }else{
              
             (route.firstChild ) ? this.resolveCrumbs(route.firstChild,event) : " ";  
          }
         
    
         
     }
}
