import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConfigService } from '../_services/app-config.service';
import { Observable, forkJoin } from 'rxjs';
@Injectable({
  providedIn: 'root',
})


export class Resetvalidationdetails implements Resolve<any> {
  apiurl=AppConfigService.settings.apiServer.apiurl;
  fapiurl=AppConfigService.settings.apiServer.formapi;
  constructor(private router: Router, private http: HttpClient) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var eventlist = this.http.get<any>(this.apiurl+"events");
    var Locations = this.http.get<any>(this.apiurl+"eventlocations");
    var paymod = this.http.get<any>(this.apiurl+"paymentmodes");

    return forkJoin([eventlist, Locations, paymod]);
  }
}
