<div class="card card-body">
  <h4
    class="
      card-title
      text-uppercase
      font-weight-bold
      pb-2
      mb-4
      d-flex
      flex-column
      position-relative
    "
    style="font-size: 18px"
  >
    <div>
      WORKSHOP /DEEKSHE FEEDBACK /
      <span class="kn">ಕಾರ್ಯಾಗಾರ, ದೀಕ್ಷೆಯ ಬಗ್ಗೆ ಪ್ರತಿಕ್ರಿಯೆ</span>
    </div>
    <span
      class="pt-1 pb-0 w-50 bg-danger position-absolute"
      style="bottom: 0px; left: 0px"
    ></span>
  </h4>
  <form   [formGroup]="userform"  (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-6 form-group">
        <label for="validationServereventKey"
          >Event Name / <span class="kn">ಶಿಭಿರದ ಹೆಸರು</span></label
        >
        <select
          class="form-control"
          formControlName="workshopName"
          id="validationServereventKey"
          [ngClass]="{ 'is-invalid': submitted && f.workshopName.errors }"
        >
          <option value="">Choose...</option>
          <!---->
          <option value="Marma Workshop">Marma Workshop</option>
          <option value="Negative Energy Removal workshop">
            Negative Energy Removal workshop
          </option>
          <option value="Nidhi Nikshepana workshop">
            Nidhi Nikshepana workshop
          </option>
          <option value="Anjana Darpana workshop">
            Anjana Darpana workshop
          </option>
          <option value="Nidhi - Anjana workshop">
            Nidhi - Anjana workshop
          </option>
          <option value="Agnihotra Workshop">Agnihotra Workshop</option>
          <option value="Nitya Mudra Workshop">Nitya Mudra Workshop</option>
        </select>
        <div
          class="invalid-tooltip text-danger bg-transparent py-0"
          style="font-size: 12px"
        >
          Please Select valid event.
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="inputAddress"
        >About the workshop. Did the workshop meet your expectations? /
        <span class="kn"
          >ಕಾರ್ಯಗಾರದ ಬಗ್ಗೆ. ಈ ಕಾರ್ಯಗಾರ ನಿಮ್ಮ ನಿರೀಕ್ಷೆಗೆ ತಕ್ಕಂತೆ ಇತ್ತೆ ?</span
        ></label
      >
      <select class="form-select" formControlName="workshopExpectations"  [ngClass]="{ 'is-invalid': submitted && f.workshopExpectations.errors }">
        <option value="">Choose</option>
        <option value="Yes. Exceeded expectations">
          Yes. Exceeded expectations / ಹೌದು. ನಿರೀಕ್ಷೆಗೂ ಮೀರಿದ್ದಾಗಿದೆ.
        </option>
        <option value="Met expectations">
          Met expectations / ನಿರೀಕ್ಷೆಗೆ ತಕ್ಕಂತೆ ಇದೆ.
        </option>
        <option value="Below expectations">
          Below expectations / ನಿರೀಕ್ಷೆಗಿಂತ ಕಡಿಮೆ.
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="inputAddress"
        >About the food. / <span class="kn">ಊಟದ ಬಗ್ಗೆ.</span></label
      >
      <select class="form-select" formControlName="food"  [ngClass]="{ 'is-invalid': submitted && f.food.errors }">
        <option value="">Choose</option>
        <option value="Food was excellent">
          Food was excellent / ಆಹಾರ ಅತ್ಯುತ್ತಮವಾಗಿತ್ತು.
        </option>
        <option value="Food was good">
          Food was good / ಆಹಾರ ಚೆನ್ನಾಗಿತ್ತು.
        </option>
        <option value="Food was bad">
          Food was bad / ಆಹಾರ ಚೆನ್ನಾಗಿರಲಿಲ್ಲ.
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="inputAddress"
        >About the Organizers and co-ordinators.<span class="kn"
          >ವ್ಯವಸ್ಥಾಪಕರು ಮತ್ತು ಸಂಯೋಜಕರ ಬಗ್ಗೆ.</span
        ></label
      >
      <select class="form-select" formControlName="organizers"  [ngClass]="{ 'is-invalid': submitted && f.organizers.errors }">
        <option value="">Choose</option>
        <option value="Very supportive and interactive">
          Very supportive and interactive / ಬಹಳವಾಗಿ ಬೆಂಬಲಿಸುತ್ತಿದ್ದರು ಮತ್ತು
          ಎಲ್ಲರೊಡನೆ ಸಕ್ರಿಯವಾಗಿ ಬೆರೆಯುತ್ತಿದ್ದರು.
        </option>
        <option value="Good">Good / ಒಳ್ಳೆಯದು</option>
        <option value="Not good">Not good / ಒಳ್ಳೆಯದಾಗಿರಲಿಲ್ಲ.</option>
      </select>
      <div
        class="invalid-tooltip text-danger bg-transparent py-0"
        style="font-size: 12px"
      ></div>
    </div>
    <div class="form-group">
      <label for="validationServer02">
        Any other general feedback (Handouts, Logistics, Venue etc.......) /
        <span class="kn"
          >ಬೇರೆ ಯಾವುದೇ ಸಾಮಾನ್ಯ ಪ್ರತಿಕ್ರಿಯೆ (ಕರಪತ್ರಗಳು, ವ್ಯವಸ್ಥೆ, ಸ್ಥಳ
          ..ಇತ್ಯಾದಿ)</span
        ></label
      ><input
        class="form-control"
        formControlName="generalFeedback"
        type="email" [ngClass]="{ 'is-invalid': submitted && f.generalFeedback.errors }"
      />
      <div
        class="invalid-tooltip text-danger bg-transparent py-0"
        style="font-size: 12px"
      ></div>
    </div>
    <div class="form-group">
      <label for="inputAddress"
        >Are you willing to be a volunteer of SSBK ?
        <span class="kn"
          >ಶ್ರೀ ಶಿರಡಿ ಸಾಯಿ ಭಿಕ್ಷಾ ಕೇಂದ್ರದಲ್ಲಿ ಸ್ವಯಂ ಸೇವಕರಾಗಲು ಬಯಸುತ್ತೀರಾ ?
        </span></label
      >
      <select class="form-select" formControlName="ssbkvolunteer"  [ngClass]="{ 'is-invalid': submitted && f.ssbkvolunteer.errors }" (change)="WillvolunteerChange($event.target.value)">
        <option value="">Choose</option>
        <option value="Yes">Yes / ಹೌದು</option>
        <option value="No">No / ಇಲ್ಲ</option>
      </select>
      <div
        class="invalid-tooltip text-danger bg-transparent py-0"
        style="font-size: 12px"
      ></div>
    </div>
    <div *ngIf="Willvolunteer">
      <div class="form-group">
         <label for="validationServer02"> If yes, Which place do you stay? (your location details) / <span class="kn">ಹೌದು ಎಂದರೆ ನೀವು ವಾಸಿಸುತ್ತಿರುವ ಸ್ಥಳ ? (ನಿಮ್ಮ ಸ್ಥಳದ ವಿವರ)</span></label><input class="form-control ng-untouched ng-pristine ng-valid" formControlName="locationDetails" [ngClass]="{ 'is-invalid': submitted && f.locationDetails.errors }" type="text">
         <div class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px;"></div>
      </div>
   </div>
    <div class="row">
      <div class="col-md-6 form-group order-md-0 order-1">
        <label for="validationServer01">
          Name / <span class="kn">ಹೆಸರು</span></label
        ><input
          class="form-control"
          formControlName="name"
          placeholder="e.g., Sunil Kumar V K"
          type="text"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="validationServermobile"
          >Contact Number / <span class="kn">ಮೊಬೈಲ್ ಸಂಖ್ಯೆ </span></label
        ><input
          class="form-control"
          formControlName="mobileNumber"
          placeholder="e.g., 7892915652"
          type="text" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }"
        />
        <div
          class="invalid-tooltip text-danger bg-transparent py-0"
          style="font-size: 12px"
        >
          Please provide a valid 10 digit Mobile Number.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="validationServer02"
          >Email Id / <span class="kn">ಈ - ಮೈಲ್</span></label
        ><input class="form-control" formControlName="emailId" type="email" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" />
        <div
          class="invalid-tooltip text-danger bg-transparent py-0"
          style="font-size: 12px"
        >
          Please provide a Email Id.
        </div>
      </div>
    </div>
    <div class="form-group form-check">
      <input
        checked=""
        class="form-check-input"
        id="exampleCheck1"
        type="checkbox"
      /><label class="form-check-label" for="exampleCheck1"
        >I agree to the
        <a target="_blank" href="/terms-workshop/en"
          >Dr Sri Lakshmi Srinivas Guruji Workshop Terms,Conditions</a
        >&nbsp; &amp; <a href="/privacy">Privacy Policy</a>&nbsp;<br /><a
          target="_blank"
          href="/terms-workshop/en"
          >ಡಾ. ಶ್ರೀ ಲಕ್ಷ್ಮೀ ಶ್ರೀನಿವಾಸ ಗುರೂಜಿಯವರ ಶಿಭಿರ ನಿಯಮ, ಶರತ್ತುಗಳು, ಗೌಪ್ಯತಾ
          ನೀತಿ ಮತ್ತು ನಿಬಂಧನೆಗಳನ್ನು ನಾನು ಸರಿಯಾಗಿ ಅನುಸರಿಸಿ ಪಾಲಿಸುತ್ತೇನೆ</a
        ></label
      >
    </div>
    <div class="row justify-content-center">
      <div class="form-group col-md-4">
        <button
          class="btn btn-success btn-block btn-lg"
          type="submit"
          [disabled]="posting"
        >
          <span [ngClass]="{ 'd-none': posting }">Submit form</span>
          <div [ngClass]="{ 'd-inline-block': posting, 'd-none': !posting }">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </button>
      </div>
    </div>
  </form>
</div>
