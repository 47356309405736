import { Component,OnInit,ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule }  from '@angular/common';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



import { AuthService } from '../../_services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
     encapsulation: ViewEncapsulation.None
})
export class LoginComponent  {
    loginForm: FormGroup;
 loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
constructor(private authS: AuthService,private formBuilder: FormBuilder,private route: ActivatedRoute,
        private router: Router,){
    
}
    
      ngOnInit() {
     
this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
         
  }
    
    get f() { return this.loginForm.controls; }
    
   onSubmit() {
  let self=this;
   this.submitted = true;
 if (this.loginForm.invalid) {
  //this.alertService.error("User name and Password required",AlertPosition.CenterTop);
            return;
        }
           this.loading = true;
             setTimeout(()=>{
                 
  this.authS.login(this.f.username.value, this.f.password.value).pipe(first())
            .subscribe(
                data => {
                  if(!data){
                      this.loading = false;
                       this.submitted = false;
                      // this.alertService.error("username or password did not match *",AlertPosition.CenterTop);
               
                     }else{
                         if(this.route.snapshot.queryParams.returnUrl){
                            this.router.navigate([this.route.snapshot.queryParams.returnUrl]);
                            }else{
                               this.router.navigate(['/receipts']); 
                            }

                     }
               
                },
                error => {
                   
                    this.loading = false;
                });
   },3000);
  }
}
