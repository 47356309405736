/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./header.component";
import * as i5 from "../routedata.service";
import * as i6 from "@angular/platform-browser";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["height", "50px"], ["src", "assets/img/sssbk.png"], ["style", "\n          -webkit-filter: drop-shadow(0px 1px 1px #222);\n          filter: drop-shadow(0px 1px 1px #222);\n        "], ["width", "18.13px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "nav", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "a", [["class", "\n      navbar-brand\n      mb-0\n      mx-auto\n      d-flex\n      justify-content-center\n      align-items-center\n    "]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["height", "50px"], ["src", "assets/img/gls-sm.png"], ["width", "50px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "d-none d-md-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "d-inline-block d-md-none text-wrap"], ["style", "font-size: 16px"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, "/"); _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.IsBaba; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "navbar navbar-dark ", _co.bgColor, " mb-3 text-center sticky-top"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵinlineInterpolate(1, "h4 m-0 px-1 ", _co.titlColor, ""); _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.Title; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.Mobtitle; _ck(_v, 12, 0, currVal_7); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { TabHolderdiv: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.Title != ""); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 4308992, null, 0, i4.HeaderComponent, [i2.Router, i2.ActivatedRoute, i5.RoutedataService, i6.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i4.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
