<div class="row justify-content-center h-100 align-items-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header bg-white border-0">
          <h5 class="card-title"></h5>
        </div>
        <div></div>
        <!---->
        <div class="card-body text-center">
          <div>
            <div class="success-checkmark">
              <div
                [ngClass]="{
                  'check-icon': PaymentStatus == 'Credit',
                  'cancel-icon': PaymentStatus != 'Credit'
                }"
              >
                <span class="icon-line line-tip"></span
                ><span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </div>
          <h4
            class="text-success mb-4" *ngIf="WasPaymentAllow"
            [ngClass]="{
              'text-success': PaymentStatus == 'Credit',
              'text-danger': PaymentStatus != 'Credit'
            }" [innerHTML]="PaymentTitle"
          > </h4>
          <h5 class="text-danger font-weight-bold" *ngIf="WasPaymentAllow">
            Order Id <br />
            {{ PaymentId }}
          </h5>
          <br />
          <div [innerHTML]="PaymentMessage"> </div>  
          
        </div>
        <!---->
      </div>
    </div>
  </div>
  