import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../_services/app-config.service';
import { forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
export class Resetvalidationdetails {
    constructor(router, http) {
        this.router = router;
        this.http = http;
        this.apiurl = AppConfigService.settings.apiServer.apiurl;
        this.fapiurl = AppConfigService.settings.apiServer.formapi;
    }
    resolve(route, state) {
        var eventlist = this.http.get(this.apiurl + "events");
        var Locations = this.http.get(this.apiurl + "eventlocations");
        var paymod = this.http.get(this.apiurl + "paymentmodes");
        return forkJoin([eventlist, Locations, paymod]);
    }
}
Resetvalidationdetails.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Resetvalidationdetails_Factory() { return new Resetvalidationdetails(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient)); }, token: Resetvalidationdetails, providedIn: "root" });
