import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class RoutedataService {
    constructor(router, activatedRoute) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.currentroute = {};
        this.PLSubject = new BehaviorSubject(undefined);
        this.PLUpdate = this.PLSubject.asObservable();
        router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe((event) => {
            this.resolveCrumbs(router.routerState.snapshot.root, event);
        });
    }
    resolveCrumbs(route, event) {
        const data = route.routeConfig && route.routeConfig.data;
        if (data) {
            this.currentroute = {
                eve: event,
                data: data
            };
            this.PLSubject.next(this.currentroute);
        }
        else {
            (route.firstChild) ? this.resolveCrumbs(route.firstChild, event) : " ";
        }
    }
}
RoutedataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoutedataService_Factory() { return new RoutedataService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: RoutedataService, providedIn: "root" });
