<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <div class="mb-3">
                    <input type="text" class="form-control" formControlName="username"  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Email" autocomplete="username" required>
                  </div>
                  <div class="mb-3">
                    <input type="password" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" autocomplete="current-password" required>
                  </div>
                  
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary px-4" data-style="expand-right"   [disabled]="loading">Login</button>
                        
                    </div>
                    <div class="col-6 text-right d-none">
                      <button type="button" class="btn btn-link px-0">Forgot password?</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

