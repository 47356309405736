import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
 pdfSource =  window.location.origin+"/assets/terms_en.pdf";
  constructor(private route:ActivatedRoute) { }

  ngOnInit() {
      
      if(this.route.snapshot.params.lang){
           this.pdfSource =  window.location.origin+"/assets/terms_ka.pdf";
      }
  }

}
