import { Component, OnInit, ElementRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Event, Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {AppConfigService} from '../../../_services/app-config.service';
declare var $: any;

@Component({
  selector: 'app-workshop-feedback',
  templateUrl: './workshop-feedback.component.html',
  styleUrls: ['./workshop-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkshopFeedbackComponent implements OnInit {
  
  userform: FormGroup;
  submitted: boolean = false;
  posting: boolean = false;
  Willvolunteer:boolean=false;
  regionKey: string = '';
  area: string = "";
  district: string = "";
  state: string = "";
  imageSrc: string = "";
  btnName = "Submit";

  isImageupload = false;
  pdfSource = window.location.origin + "/assets/workshop-terms.pdf";
  apiurl = AppConfigService.settings.apiServer.apiurl;
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private el: ElementRef, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    let self = this;
   
    this.userform = this.formBuilder.group({ 
      "workshopName": ["", Validators.required], 
      "name": ['', Validators.required ],
      "workshopExpectations": ['', Validators.required],
      "mobileNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]], 
      "emailId": ['', [Validators.required, Validators.email]],
      "food": ['', Validators.required],
      "organizers": ['', Validators.required],
      "generalFeedback": '',
      "ssbkvolunteer": ['', Validators.required]

    });
    
  }
  WillvolunteerChange(val){
if(val=="Yes"){
this.Willvolunteer=true;
this.userform.addControl( "locationDetails", this.formBuilder.control('',[Validators.required]) );
}else{
this.Willvolunteer=false;
this.userform.removeControl("locationDetails");
}
  }
  get f() {
    return this.userform.controls;
  }
  
  private getTopOffset(controlEl: HTMLElement): number {
   const labelOffset = 130;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
     
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .is-invalid"
    );
     
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
  }

  onSubmit() {
    this.submitted = true;
    
    if (!this.userform.invalid) {

      var postpara = {};
      var formData: any = new FormData();
      Object.keys(this.userform.controls).forEach(key => {
        if (key != "PhotoUpload") {
          if (key == "dOB") {
            postpara[key] = new Date(this.f[key].value);
          } else {
            postpara[key] = this.f[key].value;
          }

          formData.append(key, this.f[key].value);
        }
      });
       
      this.posting = true;

      this.http.post<any>(this.apiurl + "workshopfeedbacks/", postpara).subscribe((data) => {
        this.posting = false;
        this.submitted = false;
        this.regionKey = "";
        this.area = "";
        this.district = "";
        this.state = "";
        this.imageSrc = "";
         
        this.userform.reset();
        $('#feedbackformmodal').modal('show');

      },
        (error) => {                              //Error callback
          console.error('error caught in component ' + error)
          this.posting = false;
          this.submitted = false;
        });




    } else {
      setTimeout(()=>{
        this.scrollToFirstInvalidControl();
      },20);
      
    }

    return false;
  }

}
