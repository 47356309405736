import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HomeComponent } from './views/home/home.component';
import { TermsComponent } from './views/terms/terms.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { AgnihotraComponent } from './views/agnihotra/agnihotra.component';
import { WorkshopFeedbackComponent } from './views/agnihotra/workshop-feedback/workshop-feedback.component';
import { Resetvalidationdetails, ReceiptInit } from './resolve';
import { AuthGuard } from './helper/auth.guard';
import { TestComponent } from './views/test/test.component';
import { CashServicesComponent } from './views/cash-services/cash-services.component';
import { LoginComponent } from './views/login/login.component';
import { ReceiptgridComponent } from './views/cash-services/receiptgrid/receiptgrid.component';
import { AgnihotraThankyouComponent } from './views/agnihotra/agnihotra-thankyou/agnihotra-thankyou.component';
const routes: Routes = [{
        path: '',
        component: HomeComponent, data: {
                navbg: 'bg-warning',
                title: "Sri Shirdi Sai Biksha Kendra (R) || ಶ್ರೀ ಶಿರಡಿ ಸಾಯಿ ಭಿಕ್ಷಾ ಕೇಂದ್ರ (ರಿ)",
                mobtitle: "SSBK",
                titlecolor: "text-danger",
                docTitle: "Dr Guruji Lakshmi Srinivas & Sri Shirdi Sai Biksha Kendra(SSBK) Agnihotra Registration",
                ISBaba: "1"
        }

}, {
        path: 'agnihotra-registration',
        children: [{
                path: '',
                component: AgnihotraComponent, data: {
                        navbg: 'bg-danger',
                        title: "Dr. Sri Lakshmi Srinivas Guruji",
                        mobtitle: "Dr. Sri Lakshmi Srinivas Guruji",
                        titlecolor: "text-white",
                        docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
                        ISBaba: "0"
                }, resolve: {
                        init: Resetvalidationdetails
                }
        }, {
                path: 'thankyou',
                component: AgnihotraThankyouComponent, data: {
                        navbg: 'bg-danger',
                        title: "Dr. Sri Lakshmi Srinivas Guruji",
                        mobtitle: "Dr. Sri Lakshmi Srinivas Guruji",
                        titlecolor: "text-white",
                        docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
                        ISBaba: "0"
                }
        }]
}, {
        path: 'workshop-feedback',
        component: WorkshopFeedbackComponent, data: {
                navbg: 'bg-danger',
                title: "Dr. Sri Lakshmi Srinivas Guruji",
                mobtitle: "Dr. Sri Lakshmi Srinivas Guruji",
                titlecolor: "text-white",
                docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
                ISBaba: "0"
        }

}, {
        path: 'terms',
        component: TermsComponent, data: {
                navbg: 'bg-warning',
                mobtitle: "SSBK",
                titlecolor: "text-danger",
                docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
                ISBaba: "1"
        }, resolve: {
                init: Resetvalidationdetails
        }

}, {
        path: 'terms/:lang',
        component: TermsComponent, data: {
                navbg: 'bg-warning',
                mobtitle: "SSBK",
                titlecolor: "text-danger",
                docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
                ISBaba: "1"
        }

}, {
        path: 'privacy',
        component: PrivacyComponent, data: {
                navbg: 'bg-warning',
                mobtitle: "SSBK",
                titlecolor: "text-danger",
                docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
                ISBaba: "1"
        }

},
{
        path: 'login',
        component: LoginComponent,
        data: {
                title: 'Login Page'
        }
}];

@NgModule({
        imports: [RouterModule.forRoot(routes)],
        exports: [RouterModule]
})
export class AppRoutingModule { }
