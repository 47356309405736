import { Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { TermsComponent } from './views/terms/terms.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { AgnihotraComponent } from './views/agnihotra/agnihotra.component';
import { WorkshopFeedbackComponent } from './views/agnihotra/workshop-feedback/workshop-feedback.component';
import { Resetvalidationdetails } from './resolve';
import { LoginComponent } from './views/login/login.component';
import { AgnihotraThankyouComponent } from './views/agnihotra/agnihotra-thankyou/agnihotra-thankyou.component';
const ɵ0 = {
    navbg: 'bg-warning',
    title: "Sri Shirdi Sai Biksha Kendra (R) || ಶ್ರೀ ಶಿರಡಿ ಸಾಯಿ ಭಿಕ್ಷಾ ಕೇಂದ್ರ (ರಿ)",
    mobtitle: "SSBK",
    titlecolor: "text-danger",
    docTitle: "Dr Guruji Lakshmi Srinivas & Sri Shirdi Sai Biksha Kendra(SSBK) Agnihotra Registration",
    ISBaba: "1"
}, ɵ1 = {
    navbg: 'bg-danger',
    title: "Dr. Sri Lakshmi Srinivas Guruji",
    mobtitle: "Dr. Sri Lakshmi Srinivas Guruji",
    titlecolor: "text-white",
    docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
    ISBaba: "0"
}, ɵ2 = {
    navbg: 'bg-danger',
    title: "Dr. Sri Lakshmi Srinivas Guruji",
    mobtitle: "Dr. Sri Lakshmi Srinivas Guruji",
    titlecolor: "text-white",
    docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
    ISBaba: "0"
}, ɵ3 = {
    navbg: 'bg-danger',
    title: "Dr. Sri Lakshmi Srinivas Guruji",
    mobtitle: "Dr. Sri Lakshmi Srinivas Guruji",
    titlecolor: "text-white",
    docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
    ISBaba: "0"
}, ɵ4 = {
    navbg: 'bg-warning',
    mobtitle: "SSBK",
    titlecolor: "text-danger",
    docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
    ISBaba: "1"
}, ɵ5 = {
    navbg: 'bg-warning',
    mobtitle: "SSBK",
    titlecolor: "text-danger",
    docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
    ISBaba: "1"
}, ɵ6 = {
    navbg: 'bg-warning',
    mobtitle: "SSBK",
    titlecolor: "text-danger",
    docTitle: "Welcome to Dr Guruji Lakshmi Srinivas & SSBK Agnihotra Registration",
    ISBaba: "1"
}, ɵ7 = {
    title: 'Login Page'
};
const routes = [{
        path: '',
        component: HomeComponent, data: ɵ0
    }, {
        path: 'agnihotra-registration',
        children: [{
                path: '',
                component: AgnihotraComponent, data: ɵ1, resolve: {
                    init: Resetvalidationdetails
                }
            }, {
                path: 'thankyou',
                component: AgnihotraThankyouComponent, data: ɵ2
            }]
    }, {
        path: 'workshop-feedback',
        component: WorkshopFeedbackComponent, data: ɵ3
    }, {
        path: 'terms',
        component: TermsComponent, data: ɵ4, resolve: {
            init: Resetvalidationdetails
        }
    }, {
        path: 'terms/:lang',
        component: TermsComponent, data: ɵ5
    }, {
        path: 'privacy',
        component: PrivacyComponent, data: ɵ6
    },
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ7
    }];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
