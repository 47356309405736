<footer class="py-2 bg-dark text-white">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6 text-center text-md-start">
        <p class="m-0">Copyright © {{currentYear}} All Rights Reserved</p>
      </div>
      <div class="col-md-6 text-center text-md-end">
        <a href="https://www.jalattics.com/" target="_blank"
          ><img src="assets/img/j_logo.png"
        /></a>
      </div>
    </div>
  </div>
</footer>
