import { Component } from '@angular/core';
declare var bootstrap:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
     constructor() { }

  ngOnInit() {
  }
  copyMsg(){
    var copyText = document.getElementById("Receiptmessage"); 
 
  navigator.clipboard.writeText(copyText.textContent);
  }
}
