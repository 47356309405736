<div class="card card-body">
            <h4 class="card-title text-uppercase font-weight-bold pb-2 mb-4 d-flex flex-column position-relative" style="font-size: 18px;">Volunteers Details
            <span class="pt-1 pb-0 w-50 bg-danger position-absolute" style="bottom: 0px;"></span>
            </h4>
<form [formGroup]="userform" (ngSubmit)="onSubmit()">
 
    <div class="row  position-relative">
    <div class="col-md-6 form-group order-md-0 order-1">
      <label for="validationServer01">Full Name / <span class="kn">ಹೆಸರು</span></label>
      <input type="text" class="form-control"  formControlName="fullName"  [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }">
        </div>
        <div class="col-md-6 form-group d-flex justify-content-center order-0 order-md-1">
            <div class="avatarholder align-self-end">
         <div class="custom-file avatarupload position-relative d-flex flex-column">

  <label class="custom-file-label p-0" for="customFile">
    <input type="file" class="custom-file-input invisible "formControlName="PhotoUpload" [ngClass]="{ 'is-invalid': submitted && f.PhotoUpload.errors }" (change)="onFileChange($event)" id="customFile" accept="image/x-png,image/gif,image/jpeg">
           <img [src]="imageSrc" *ngIf="imageSrc!=''" class="img-fluid rounded">
            <img src="https://placehold.co/200x200" *ngIf="imageSrc==''" class="img-fluid rounded">
                 
            </label>
             <div  class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center" style="z-index: 100;" *ngIf="isImageupload"><div class="spinner-border spinner-border-sm text-warning" role="status">
  
  </div></div>
             
</div>
                <label for="customFile" class="text-center w-100 text-danger">Upload Photo</label>
          </div>  
            
        </div>
    </div>
    
    
    <div class="row">
    <div class="col-md-6 form-group">
      <label for="validationServermobile">Mobile Number / <span class="kn">ಮೊಬೈಲ್ ಸಂಖ್ಯೆ</span></label>
      <input type="text" class="form-control" formControlName="mobileNumber" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }" >
       <div  class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px;">
        Please provide a valid Mobile Number.
      </div>
    </div>
        
         <div class="col-md-6 form-group">
      <label for="validationServerwpnum">WhatsApp Number / <span class="kn">ವಾಟ್ಸ್ಅಪ್ ಸಂಖ್ಯೆ</span></label>
      <input type="text" class="form-control" formControlName="whatsappNumber" [ngClass]="{ 'is-invalid': submitted && f.whatsappNumber.errors }" >
       <div  class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px;">
        Please provide a valid Mobile Number.
      </div>
    </div>
  
    </div>
    
     <div class="row">
           <div class="col-md-6 form-group">
      <label for="validationServerph">Phone # with STD Code <br> <span class="kn">ತುರ್ತು ಪರಿಸ್ಥಿತಿಯಲ್ಲಿ ಸಂರ್ಪಕಿಸಲು ದೂರವಾಣಿ ಸಂಖ್ಯೆ</span> </label>
      <input type="text" class="form-control" formControlName="landlineNumber" [ngClass]="{ 'is-invalid': submitted && f.landlineNumber.errors }">
      <div  class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px;">
        Please provide a valid Mobile Number.
      </div>
    </div>
    <div class="col-md-6 form-group">
      <label for="validationServer02">Emergency Contact Number  <br> <span class="kn">ತುರ್ತು ಪರಿಸ್ಥಿತಿಯಲ್ಲಿ ಸಂರ್ಪಕಿಸಲು ದೂರವಾಣಿ ಸಂಖ್ಯೆ</span> </label>
      <input type="text" class="form-control" formControlName="emergencyContact" [ngClass]="{ 'is-invalid': submitted && f.emergencyContact.errors }">
      <div  class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px;">
        Please provide a valid Mobile Number.
      </div>
    </div>
    </div>
    
    
    <div class="form-group">
    <label for="inputAddress">Address / <span class="kn">ವಿಳಾಸ</span></label>
    <input type="text" class="form-control"  placeholder="1234 Main St" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
  </div>
     <div class="form-group position-relative">
      <label >Area Pin code / <span class="kn">ಅಂಚೆ ಸಂಖೆ</span></label>
      <input type="text" class="form-control " formControlName="pincode" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" >
      <div id="validationServer05Feedback"  class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px;">
        Please provide a valid zip.
      </div>
    </div>
    
    <div class="form-group" [ngClass]="{ 'd-none':regionKey==''}">
        <div class="bg-light border  p-1">
       {{area}}, {{district}}, {{state}}
            </div>
    </div>
    
     <div class="row">
    <div class="form-group col-md-6">
      <label for="validationServer02">Email Id  / <span class="kn">ಇ ಮೇಲ್</span> </label>
      <input type="email" class="form-control" formControlName="emailId" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
       <div  class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px;">
        Please provide a Email Id.
      </div>
    </div>
    
    <div class="form-group col-md-6">
      <label >Date of birth  / <span class="kn"> ಹುಟ್ಟಿದ ದಿನಾಂಕ</span></label>
       <div class="custom-datefield">
      <input type="text" class="form-control invisible position-absolute" style="z-index: -1;   visibility: hidden;" id="dobpick" formControlName="dob" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">
           </div>
           
      
    </div>
    
    </div>
   
     <div class="row">
   
    <div class="form-group col-md-6">
      <label >Anniversary Date  <br> <span class="kn"> ಮದುವೆಯ ವಾರ್ಷಿಕೋತ್ಸವದ ದಿನಾಂಕ </span></label>
         <div class="custom-datefield">
      <input type="text" class="form-control position-absolute" id="doapicker" formControlName="doa" [ngClass]="{ 'is-invalid': submitted && f.doa.errors }" >
        </div>
      
    </div>
          <div class="form-group col-md-6">
      <label >Date of Joining SSBK <br> <span class="kn">ಶಿಸಾಭಿಕೇಂದ್ರದಲ್ಲಿ ಸೇರಿದ ದಿನಾಂಕ (ಅಂದಾಜು)</span></label>
    <div class="custom-datefield joindate">
      <input type="text" class="form-control position-absolute" id="dojpicker" formControlName="doj" [ngClass]="{ 'is-invalid': submitted && f.doj.errors }" >
        </div>
      
    </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
         <label >Gender / <span class="kn">ಲಿಂಗ</span> </label>
         <div>
    <div class="form-check  form-check-inline">
  <input type="radio" id="customRadioInline1" value="male" name="gender" class="form-check-input" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
  <label class="form-check-label" for="customRadioInline1">Male</label>
</div>
<div class="form-check  form-check-inline">
  <input type="radio" id="customRadioInline2" value="female" name="gender" class="form-check-input" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
  <label class="form-check-label" for="customRadioInline2">Female</label>
</div>
        </div> 
         
    </div>
        <div class="form-group col-md-6">
      <label >Blood Group / <span class="kn">ರಕ್ತದ ಗುಂಪು</span></label>
      <select class="form-select" name="bloodGroup" formControlName="bloodGroup" [ngClass]="{ 'is-invalid': submitted && f.bloodGroup.errors }">
           <option value="" selected>Select Blood Group</option>
<option value="A+" >A+</option><option value="A-">A-</option>
<option value="B+">B+</option><option value="B-">B-</option>
<option value="O+">O+</option><option value="O-">O-</option>
<option value="AB+">AB+</option><option value="AB-">AB-</option>
</select>
    </div>
    </div>
     <div class="row">
   
    <div class="form-group col-md-6">
      <label >Education <br> <span class="kn">ವಿದ್ಯಾಭ್ಯಾಸ</span></label>
      <input type="text" class="form-control" formControlName="qualifications" [ngClass]="{ 'is-invalid': submitted && f.qualifications.errors }">
      
    </div>
          <div class="form-group col-md-6">
      <label >Present Employment Details <br><span class="kn">ಪ್ರಸ್ತುತ ಉದ್ಯೋಗದ ವಿವರ</span></label>
      <input type="text" class="form-control"  formControlName="presentEmploymentDetails"  [ngClass]="{ 'is-invalid': submitted && f.presentEmploymentDetails.errors }">
      
    </div>
    </div>
    
      <div class="form-group">
    <label for="inputAddress">Previous Experience in Spiritual Centers/NGO/Event Management (Please describe) <br> <span class="kn"> ಈ ಹಿಂದೆ ಆದ್ಯಾತ್ಮಿಕ ಕೇಂದ್ರಗಳು /  ಸರ್ಕಾರೇತರ ಸಂಸ್ಥೆ / ಕಾರ್ಯಕ್ರಮ ನಿರ್ವಹಣೆಗಳಲ್ಲಿ ಅನುಭವ ಹೊಂದಿರುವಿರೆ (ವಿವರಿಸಿ)</span></label>
    <input type="text" class="form-control"  placeholder="" formControlName="previousExperience" [ngClass]="{ 'is-invalid': submitted && f.previousExperience.errors }">
  </div>
    
    
      <div class="form-group">
    <label for="inputAddress">Reason for Joining SSBK / <span class="kn"> ಶಿಸಾಭಿಕೇಂದ್ರದಲ್ಲಿ ಸೇರಲು ಕಾರಣ</span></label>
    <input type="text" class="form-control"  placeholder="" formControlName="whySsbk" [ngClass]="{ 'is-invalid': submitted && f.whySsbk.errors }">
  </div>
    
      <div class="form-group">
    <label for="inputAddress">Technical Skills (What are you good at?) <br> <span class="kn">ತಾಂತ್ರಿಕ ನೈಪುಣ್ಯತೆ
(ಯಾವುದರಲ್ಲಿ ಉತ್ತಮ ಪರಿಣಿತಿ ಹೊಂದಿರುವಿರಿ?)</span></label>
    <input type="text" class="form-control"  placeholder="" formControlName="technicalSkills" [ngClass]="{ 'is-invalid': submitted && f.technicalSkills.errors }">
  </div>
    
      <div class="form-group">
    <label for="inputAddress">Hobbies
(Stamps/Coins/Gardening/Photography...) <br> <span class="kn">ಹವ್ಯಾಸಗಳು
(ಸ್ಟಂಪ್ಸ್/ನಾಣ್ಯಗಳು/ತೋಟಗಾರಿಕೆ/ಛಾಯಾಚಿತ್ರ … ಇತ್ಯದಿ)</span></label>
    <input type="text" class="form-control"  placeholder="" formControlName="hobbies" [ngClass]="{ 'is-invalid': submitted && f.hobbies.errors }">
  </div>
    
      <div class="form-group">
    <label for="inputAddress">Extra-Curricular Activities
(Yoga/ Singing/Dancing...) <br> <span class="kn">ಪಠ್ಯೇತರ ಚಟುವಟಿಕೆಗಳು
(ಯೋಗ/ಹಾಡುಗಾರಿಕೆ/ನೃತ್ಯ…)</span></label>
    <input type="text" class="form-control"  placeholder="" formControlName="extraCurricularActivites" [ngClass]="{ 'is-invalid': submitted && f.extraCurricularActivites.errors }">
  </div>
    
    <div class="form-group">
    <label for="inputAddress">Your Present Role at SSBK <br> <span class="kn">ಹಾಲಿ ಶಿಸಾಭಿಕೇಂದ್ರದಲ್ಲಿ ನಿಮ್ಮ ಪಾತ್ರ</span></label>
    <input type="text" class="form-control"  placeholder="" formControlName="presentRoleSsbk" [ngClass]="{ 'is-invalid': submitted && f.presentRoleSsbk.errors }">
  </div>
    
    
    <div class="form-group">
    <label for="exampleFormControlTextarea1">Please share any additional information about yourself: <br> <span class="kn">ನಿಮ್ಮ ಕುರಿತು ಇನ್ನೂ ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳಿ:</span></label>
    <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="aboutYourself" [ngClass]="{ 'is-invalid': submitted && f.aboutYourself.errors }" rows="3"></textarea>
  </div>
    <div class="d-flex justify-content-center mb-3">
    <h4 class="pb-3 border-bottom border-warning">Terms &amp;  Conditions</h4>
    </div>
     <div class="d-flex justify-content-center mb-3">
         <div class="nav">
         <a href="javascript:void(0);" (click)="changeterm('en')" class="nav-items nav-link mx-1">English</a>
         <a href="javascript:void(0);" (click)="changeterm('kn')" class="nav-items nav-link mx-1">Kannada</a>
         </div>
    </div>
<div class="pdf_v mb-4">
    <pdf-viewer [src]="pdfSource"
              [original-size]="false"></pdf-viewer>
          </div>
  <div class="form-group form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" checked>
      <label class="form-check-label" for="exampleCheck1">I agree to the <a [routerLink]="['/terms']" target="_blank">SSBK Terms &amp;  Conditions</a>&nbsp; <a [routerLink]="['/terms','kn']" target="_blank">(ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು)</a> &amp;  <a [routerLink]="['/privacy']" target="_blank">Privacy Policy</a></label>
  </div>
    <div class="row justify-content-center">
      <div class="form-group col-md-4 ">
  <button class="btn btn-success btn-block btn-lg" type="submit" [disabled]="posting"><span [ngClass]="{'d-none':posting}">Submit form</span><div  [ngClass]="{'d-inline-block':posting,'d-none':!posting}"><div class="spinner-border spinner-border-sm " role="status">
  
  </div></div></button>
    </div>
    </div>
    
</form>
            </div>