<div class=" mb-3">
  <h4 class="
 
      text-uppercase
      fw-bold
      pb-2
      mb-4
      d-flex
      flex-column jusity-content-center align-items-center
      position-relative text-hazel
    "  >
    AGNIHOTRA  ENROLLMENT
    <span class="pt-1 pb-0 w-50 bg-indigo mt-2" style="bottom: 0px"></span>
  </h4>
</div>
  <div class="card card-body">
   <!--<div class="v-spinner">
      <div class="v-sync v-sync1" style=" animation-delay: 0.07s;">
      </div><div class="v-sync v-sync2" style="animation-delay: 0.14s;">
      </div><div class="v-sync v-sync3" style=" animation-delay: 0.21s;">
      </div>
    </div>-->
    <div *ngIf="stepForm==1">
      
  <div class="main-container  mb-4" *ngIf="pageTitle  || docContent  ||  pageTitle_Kn  || docContent_Kn  ">
    <div *ngIf="tabContents.length!=0">
  
      <div   id="myTabContent">
        <div class="  " [ngClass]="{'active':idx==0,'show':idx==0}" *ngFor="let tab of tabContents;let idx=index;" [id]="seoString(tab['TabTitle'])" role="tabpanel" [attr.aria-labelledby]="seoString(tab['TabTitle'])+'-tab'">
          <h5 class="text-center" [innerHTML]="tab['docTitle']">  </h5>
          <div class=" h-100 p-2 tabDoccontent" [innerHTML]="tab['docContent']" > </div>
        </div>
        
      </div>
          </div>
    <div class="mb-3" *ngIf="pageTitle&&pageTitle!='' || docContent&&docContent!=''">
      <h5 class="text-center" [innerHTML]="pageTitle" *ngIf="pageTitle&&pageTitle!=''">  </h5>
     
 <div class="card card-body bg-pink bg-opacity-10 fs-13 text-pink h-100 p-2" [innerHTML]="docContent" *ngIf="docContent&&docContent!=''"> </div>
</div>

<div *ngIf="pageTitle_Kn&&pageTitle_Kn!='' || docContent_Kn&&docContent_Kn!=''">
  <h5 class="text-center" [innerHTML]="pageTitle_Kn" *ngIf="pageTitle_Kn&&pageTitle_Kn!=''">  </h5> 
  <div class="card card-body h-100 p-2" [innerHTML]="docContent_Kn" *ngIf="docContent_Kn&&docContent_Kn!=''"> </div>
 </div>

</div>
</div>
    <div *ngIf="stepForm==2">
      <form [formGroup]="personalDetails" >
      <div class="row">
        <div class="col-md-6 order-md-0 order-1">
          <div class=" form-group">
          <label for="validationServer01">Full Name* / <span class="kn">ಪೂರ್ಣ ಹೆಸರು</span></label>
          <input type="text" class="form-control" formControlName="fullName" placeholder="e.g., Sunil Kumar V K"
            [ngClass]="{ 'is-invalid': stepValid[2] && personal.fullName.errors }" />
          </div>
          <div class=" form-group">
            <label for="validationServermobile">Mobile Number* / <span class="kn">ಮೊಬೈಲ್ ಸಂಖ್ಯೆ</span></label>
            <div class="input-group">
              <select class="form-select wd-25" id="inputGroupSelect04" [(ngModel)]="selectedMobileCallingCode" [ngModelOptions]="{standalone: true}">
                <option value="" disabled selected>Calling Code</option>
     <option *ngFor="let country of countries" [value]="country.callingCode">{{country.name}}({{country.callingCode}})</option>
              </select>
            <input type="text" class="form-control wd-75" formControlName="mobileNumber" maxlength="10"
              [ngClass]="{ 'is-invalid': stepValid[2]  && personal.mobileNumber.errors }" />
            <div class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px">
              Please provide a valid Mobile Number.
            </div>
            </div>
          </div>
          <div class="form-group">
            <label for="validationServerwpnum">WhatsApp Number* / <span class="kn">ವಾಟ್ಸ್ಅಪ್ ಸಂಖ್ಯೆ</span></label>
            <div class="input-group">
              <select class="form-select wd-25" id="inputGroupSelect04" [(ngModel)]="selectedWhatsappCallingCode" [ngModelOptions]="{standalone: true}">
                <option value="" disabled selected>Calling Code</option>
     <option *ngFor="let country of countries" [value]="country.callingCode">{{country.name}}({{country.callingCode}})</option>
              </select>
             
            <input type="text" class="form-control wd-75" formControlName="whatsappNumber" maxlength="10"
              [ngClass]="{ 'is-invalid': stepValid[2]  && personal.whatsappNumber.errors }" />
            <div class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px">
              Please provide a valid Mobile Number.
            </div>
          
            </div>
          </div>
          <div class=" form-group">
            <label for="validationServer02">Emergency Contact Number* <br />
              <span class="kn">ತುರ್ತು ಪರಿಸ್ಥಿತಿಯಲ್ಲಿ ಸಂರ್ಪಕಿಸಲು ದೂರವಾಣಿ ಸಂಖ್ಯೆ</span>
            </label>
            <div class="input-group">
              <select class="form-select wd-25" id="inputGroupSelect04" [(ngModel)]="selectedEmergencyCallingCode" [ngModelOptions]="{standalone: true}">
                <option value="" disabled selected>Calling Code</option>
     <option *ngFor="let country of countries" [value]="country.callingCode">{{country.name}}({{country.callingCode}})</option>
              </select>
            <input type="text" class="form-control wd-75" formControlName="emergencyContact" maxlength="10"
              [ngClass]="{ 'is-invalid': stepValid[2]  && personal.emergencyContact.errors }" />
            <div class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px">
              Please provide a valid Mobile Number.
            </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 order-md-1 order-0 d-flex justify-content-center">
          <div class="d-flex align-items-center">
            <div class="card p-3"  [ngClass]="{ 'border-danger': stepValid[2]  && personal.PhotoUpload.errors }">

              <div class="avatarholder ">
                <div class="
                    custom-file
                    avatarupload
                    position-relative
                    d-flex
                    flex-column
                  ">
                
                  <label class="custom-file-label p-0" for="customFile">
                    <input type="file" class="custom-file-input invisible position-absolute" formControlName="PhotoUpload"
                    [ngClass]="{ 'is-invalid': stepValid[2]  && personal.PhotoUpload.errors }" (change)="onFileChange($event)"
                    id="customFile" accept="image/x-png,image/gif,image/jpeg" />
                    <img [src]="imageSrc" *ngIf="imageSrc != ''" class="img-fluid rounded" height="200" width="200" />
                    <img src="https://placehold.co/200x200/6f42c1/white?text=Upload+Photo&font=open-sans" *ngIf="imageSrc == ''" class="img-fluid rounded" />
                  </label>
                  <div class="
                      position-absolute
                      w-100
                      h-100
                      d-flex
                      justify-content-center
                      align-items-center bg-grey bg-opacity-75
                    " style="z-index: 100" *ngIf="isImageupload">
                    <div class="spinner-border spinner-border-sm text-danger" role="status"></div>
                  </div>
                </div>
                <label for="customFile" class="text-center w-100 text-danger">Upload Photo</label>
              </div>
            </div>
           
          </div>
          </div>

      </div>

      
     </form>
    </div>
    <div *ngIf="stepForm==3">
      <form [formGroup]="addressDetails" >
      <div class="form-group">
        <label for="inputAddress">Address / <span class="kn">ವಿಳಾಸ</span></label>
        <input type="text" class="form-control" placeholder="1234 Main St" formControlName="address"
          [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.address.errors }" />
      </div>
        
    <div class="form-group position-relative">
      <label>Area Pin code* / <span class="kn">ಅಂಚೆ ಸಂಖೆ</span></label>
      <input type="text" class="form-control" formControlName="pincode" maxlength="6"
        [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.pincode.errors }" />
      <div id="validationServer05Feedback" class="invalid-tooltip text-danger bg-transparent py-0"
        style="font-size: 12px">
        Please provide a 6 digit valid pincode.
      </div>
    </div>

    <div class="form-group" [ngClass]="{ 'd-none': regionKey == '' }">
      <div class="bg-info bg-opacity-10 border-info text-info border rounded p-1">
        {{ area }}, {{ district }}, {{ state }}
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-6">
        <label for="validationServer02">Email Id* / <span class="kn">ಇ ಮೇಲ್</span>
        </label>
        <input type="email" class="form-control" formControlName="emailId"
          [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.emailId.errors }" />
        <div class="invalid-tooltip text-danger bg-transparent py-0" style="font-size: 12px">
          Please provide a Email Id.
        </div>
      </div>

      <div class="form-group col-md-6">
        <label>Date of birth / <span class="kn"> ಹುಟ್ಟಿದ ದಿನಾಂಕ</span></label>
        <div class="custom-datefield">
          <input type="text" class="form-control invisible position-absolute" style="z-index: -1; visibility: hidden"
            id="dobpick" formControlName="dob" [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.dob.errors }" />
        </div>
      </div>
    </div>


    <div class="row">
      <div class="form-group col-md-6">
        <label>Gender / <span class="kn">ಲಿಂಗ</span> </label>
        <div>
          <div class="form-check  form-check-inline  d-inline-block p-2 overflow-hidden">
            <input type="radio" id="customRadioInline1" value="male" name="gender" class="form-check-input invisible position-absolute"
              formControlName="gender" [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.gender.errors }" />
            <label class="form-check-label border border-2 p-2 text-center rounded d-flex align-items-center flex-column justify-content-center" style="width: 100px;" for="customRadioInline1" [ngClass]="{ 'border-danger': stepValid[3]  && addressD.gender.errors }"><img
              src="assets/img/001-male.png"
              width="32px"
              height="32px"
            
            /><span>Male</span></label>
          </div>
          <div class="form-check  form-check-inline   d-inline-block p-2 overflow-hidden">
            <input type="radio" id="customRadioInline2" value="female" name="gender" class="form-check-input invisible position-absolute"
              formControlName="gender" [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.gender.errors }" />
            <label class="form-check-label border border-2 p-2 text-center rounded d-flex align-items-center flex-column justify-content-center" style="width: 100px;" for="customRadioInline2" [ngClass]="{ 'border-danger': stepValid[3]  && addressD.gender.errors }">  <img
              src="assets/img/002-female.png"
              width="32px"
              height="32px"
            
            />Female</label>
          </div>
          <div class="form-check  form-check-inline   d-inline-block p-2 overflow-hidden">
            <input type="radio" id="customRadioInline3" value="Transgender" name="gender" class="form-check-input invisible position-absolute"
              formControlName="gender" [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.gender.errors }" />
            <label class="form-check-label border border-2 p-2 text-center rounded d-flex align-items-center flex-column justify-content-center" style="width: 100px;" for="customRadioInline3" [ngClass]="{ 'border-danger': stepValid[3]  && addressD.gender.errors }"><img
              src="assets/img/003-bigender.png"
              width="32px"
              height="32px"
            
            /> Transgender</label>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Blood Group / <span class="kn">ರಕ್ತದ ಗುಂಪು</span></label>
        <select class="form-select" name="bloodGroup" formControlName="bloodGroup"
          [ngClass]="{ 'is-invalid': stepValid[3]  && addressD.bloodGroup.errors }">
          <option value="" selected>Select Blood Group</option>
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="O+">O+</option>
          <option value="O-">O-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
        </select>
      </div>
    </div>
</form>

    </div>
    <div *ngIf="stepForm==4">
      <form [formGroup]="educationalDetails" >
      <div class="row">
        <div class="form-group col-md-6">
          <label>Education <br />
            <span class="kn">ವಿದ್ಯಾಭ್ಯಾಸ</span></label>
          <input type="text" class="form-control" formControlName="qualifications"
            [ngClass]="{ 'is-invalid': stepValid[4] && education.qualifications.errors }" />
        </div>
        <div class="form-group col-md-6">
          <label>Occupation <br /><span class="kn">ಉದ್ಯೋಗ</span></label>
          <input type="text" class="form-control" formControlName="Occupation" [ngClass]="{
              'is-invalid': stepValid[4] && education.Occupation.errors
            }" />
        </div>
      </div>
      </form>
    </div>
    <div *ngIf="stepForm==5">
      <form [formGroup]="experienceDetails" >
      <div class="form-group">
        <label for="inputAddress">Previous Experience in Spiritual Centers/NGO/Event Management (Please
          describe) <br />
          <span class="kn">
            ಈ ಹಿಂದೆ ಆದ್ಯಾತ್ಮಿಕ ಕೇಂದ್ರಗಳು / ಸರ್ಕಾರೇತರ ಸಂಸ್ಥೆ / ಕಾರ್ಯಕ್ರಮ
            ನಿರ್ವಹಣೆಗಳಲ್ಲಿ ಅನುಭವ ಹೊಂದಿರುವಿರೆ (ವಿವರಿಸಿ)</span></label>
        <input type="text" class="form-control" placeholder="" formControlName="previousExperience"
          [ngClass]="{ 'is-invalid': stepValid[5] && experience.previousExperience.errors }" />
      </div>


   

      <div class="form-group">
        <label>Purpose of Attending workshop /
          <span class="kn">ಈ ಶಿಭಿರದಲ್ಲಿ ಭಾಗವಹಿಸುವ ಉದ್ದೇಶ </span></label>
        <ng-multiselect-dropdown [placeholder]="'Select Purpose'" [settings]="dropdownSettings" [data]="dropdownList"
          [(ngModel)]="PurposeSelected" [ngModelOptions]="{ standalone: true }" (onSelect)="onItemSelect($event)"
          (onDeSelect)="onItemDeSelect($event)">
        </ng-multiselect-dropdown>
      </div>
      <div class="form-group">
        <label for="anyissue">Any issues / problems you wish to share /
          <span class="kn">ಯಾವುದೇ ಸಮಸ್ಯೆಗಳು / ತೊಂದರೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಬಯಸುತ್ತೀರಾ</span></label>
        <input type="text" class="form-control" formControlName="specificIssue"
          placeholder="e.g., I have asthama problem would like to get it cured etc " [ngClass]="{
            'is-invalid': stepValid[5] && experience.specificIssue.errors
          }" />
      </div>
  
      <div class="form-group">
        <label>
          Have you attended any previous workshops of Guruji? Please share
          details.
          <span class="kn">ನೀವು ಈ ಹಿಂದೆ ಗುರೂಜಿಯವರ ಕಾರ್ಯಾಗಾರಗಳಲ್ಲಿ ಭಾಗವಹಿಸಿದ್ದೀರಾ ? ವಿವರಗಳನ್ನು
            ನೀಡಿ.</span></label>
        <ng-multiselect-dropdown [placeholder]="'Select Workshop'" [settings]="dropdownSettings" [data]="workshoplist"
          [(ngModel)]="workshopSelected" [ngModelOptions]="{ standalone: true }" (onSelect)="onWorkshopSelect($event)"
          (onDeSelect)="onWorkshopDeSelect($event)">
        </ng-multiselect-dropdown>
      </div>
      <div class="form-group">
        <label for="sourcedd">How did you come to know of this workshop ? /
          <span class="kn">ಈ ಕಾರ್ಯಾಗಾರದ ಬಗ್ಗೆ ನಿಮಗೆ ಮಾಹಿತಿ ಹೇಗೆ ದೊರಕಿತು ?</span></label>
        <select class="form-select" name="source" formControlName="source"
          [ngClass]="{ 'is-invalid': stepValid[5] && experience.source.errors }">
          <option value="" selected>Choose</option>
          <option value="TV Channel">TV Channel / ಟಿವಿ</option>
          <option value="YouTube">YouTube / ಯೂಟ್ಯೂಬ್</option>
          <option value="Facebook">Facebook / ಫೇಸ್ಬುಕ್</option>
          <option value="Whatsup">Whatsup / ವಾಟ್ಸಾಪ್</option>
          <option value="Family-Friend">Family / ಕುಟುಂಬ</option>
          <option value="Volunteer">
            Volunteer / ಎಸ್&zwnj;ಎಸ್&zwnj;ಬಿಕೆ ಸ್ವಯಂಸೇವಕರು
          </option>
          <option value="From Friends">From Friends / ಸ್ನೇಹಿತರು</option>
          <option value="Others">Others / ಇತರರು</option>
        </select>
      </div>
  
      <div class="d-flex justify-content-center mb-3">
        <h4 class="pb-3 border-bottom border-warning">Terms &amp; Conditions</h4>
      </div>
      <div class="d-flex justify-content-center mb-3">
        <!--<div class="nav">
          <a
            href="javascript:void(0);"
            (click)="changeterm('en')"
            class="nav-items nav-link mx-1"
            >English</a
          >
          <a
            href="javascript:void(0);"
            (click)="changeterm('kn')"
            class="nav-items nav-link mx-1"
            >Kannada</a
          >
        </div>-->
      </div>
      <div class="pdf_v mb-4">
        <pdf-viewer [src]="pdfSource" [original-size]="false"></pdf-viewer>
      </div>
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" checked />
        <label class="form-check-label" for="exampleCheck1">I agree to the
          <a [routerLink]="['/terms']" target="_blank">SSBK Terms &amp; Conditions</a>&nbsp;
          <a [routerLink]="['/terms', 'kn']" target="_blank">(ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು)</a>
          &amp;
          <a [routerLink]="['/privacy']" target="_blank">Privacy Policy</a></label>
      </div>
</form>
    </div>
    <div class="my-3">
<div class="d-flex justify-content-between">
  <div *ngIf="stepForm==1"></div>
  <div *ngIf="stepForm!=1"> <button type="button" class="btn btn-sand" (click)="backStep()"><i class="bi bi-arrow-left"></i> Back</button></div>
  <div *ngIf="stepForm<5">
    <button type="button" class="btn btn-pink text-white" (click)="nextStep()">Continue <i class="bi bi-arrow-right"></i></button>
    
  </div>
  <div *ngIf="stepForm==5"> 
    <button class="btn btn-success btn-block btn-lg" type="submit" [disabled]="posting" (click)="  onSubmit()">
      <span [ngClass]="{ 'd-none': posting }">Submit form</span>
      <div [ngClass]="{ 'd-inline-block': posting, 'd-none': !posting }">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
    </button>
  </div>
</div>
    </div>
    

</div>


<div class="modal" tabindex="-1" id="paymentmodal">
  <div class="modal-dialog h-100 d-flex align-items-center" style="max-width: 300px;">
    <div class="modal-content align-items-center">

      <div class="modal-header" *ngIf="!isPaymentLoading">
        <h5 class="modal-title mb-0">Payment Request</h5>
   
      </div>
      <div class="modal-body py-4">
        <div *ngIf="isPaymentLoading">
          <div class="d-flex align-items-center justify-content-center">
            <div class="v-spinner">
              <div class="v-sync v-sync1" style=" animation-delay: 0.07s;">
              </div><div class="v-sync v-sync2" style="animation-delay: 0.14s;">
              </div><div class="v-sync v-sync3" style=" animation-delay: 0.21s;">
              </div>
            </div>
          </div>
          </div>
        <div *ngIf="!isPaymentLoading">
        <p class="mb-3 text-center fw-600">{{ this.ActiveLocation["TabTitle"]}}</p>
        <p class="mb-3 fs-2 text-center fw-700">{{ this.ActiveLocation["price"]}}</p>
        <div class="d-grid gap-2">  <button type="button" class="btn btn-danger" id="instamojo" #paybtn (click)="openpayUrl()">Pay</button></div>
      </div>
      </div>
     
    </div>
  </div>
</div>