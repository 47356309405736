import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../_services/app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthService {
    constructor(http) {
        this.http = http;
        this.apiurl = AppConfigService.settings.apiServer.apiurl;
        this.UserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('admin')));
        this.User = this.UserSubject.asObservable();
    }
    get AdminUserValue() {
        return this.UserSubject.value;
    }
    login(username, password) {
        var formData = new FormData();
        formData.append("Username", username);
        formData.append("Password", password);
        var postpara = {
            Username: username,
            Password: password
        };
        return this.http.post(this.apiurl + 'userauth/login', postpara)
            .pipe(map(user => {
            if (user && user.token) {
                localStorage.setItem('admin', JSON.stringify(user));
                this.UserSubject.next(user);
                return user;
            }
            else {
            }
            return false;
        }));
    }
    logout() {
        localStorage.removeItem('admin');
        this.UserSubject.next(null);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
