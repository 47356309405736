import { BrowserModule } from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppComponent } from './app.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HomeComponent } from './views/home/home.component';
import { TermsComponent } from './views/terms/terms.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { HeaderComponent } from './control/header/header.component';
import { FooterComponent } from './control/footer/footer.component';
import { AgnihotraComponent } from './views/agnihotra/agnihotra.component';
 
import { WorkshopFeedbackComponent } from './views/agnihotra/workshop-feedback/workshop-feedback.component';
 


import { AppConfigService } from './_services/app-config.service';
import { TestComponent } from './views/test/test.component';
import { CashServicesComponent } from './views/cash-services/cash-services.component';
import { LoginComponent } from './views/login/login.component';
import { ReceiptgridComponent } from './views/cash-services/receiptgrid/receiptgrid.component';
import { AgnihotraThankyouComponent } from './views/agnihotra/agnihotra-thankyou/agnihotra-thankyou.component';
import { CommonModule } from '@angular/common';
export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.load();
  }
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TermsComponent,
    PrivacyComponent,
    HeaderComponent,
    FooterComponent,
    AgnihotraComponent, 
    WorkshopFeedbackComponent, 
    TestComponent,
    CashServicesComponent,
    LoginComponent,
    ReceiptgridComponent,
    AgnihotraThankyouComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
 
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    HttpClientModule,
    PdfViewerModule,
    NgMultiSelectDropDownModule.forRoot(),
   
  ],
  providers: [{ provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
