import { APP_BOOTSTRAP_LISTENER, Component, OnInit,ViewEncapsulation,AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { RoutedataService } from '../routedata.service';
import { Title } from "@angular/platform-browser";
import { AppConfigService } from 'src/app/_services/app-config.service';
declare var bootstrap;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit {
  bgColor = "bg-white";
  Title = "";
  pageTitle='';
  docContent:""="";
  tabContents:Array<object[]>=[];
  @ViewChild('TabHolderdiv', { static: false }) TabHolderdiv: ElementRef;

  pageTitle_Kn='';
  docContent_Kn:'';
  Mobtitle = "SSBK";
  titlColor = "text-danger";
  IsBaba: boolean = true;
  CurrentContents:object={};
   
  PageInitData=AppConfigService.settings.pagedata;
  curentcurl='';
  constructor(private router: Router, private route: ActivatedRoute, private rd: RoutedataService, private titleService: Title) {
    this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      var curl=(event.url).replace('/', '');
      var curl=(curl=='')?'home':curl;
      this.curentcurl=curl;
      
       
    });
  }
  ngAfterViewInit(){
     
    
   
  }
  seoString(str){
    return str.replace(/\s+/g, '-').toLowerCase()
  }
  ngOnInit() {
    
    this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    
      if(this.curentcurl!="agnihotra-registration"){
        if(this.PageInitData[this.curentcurl]){
          var Cdata=this.PageInitData[this.curentcurl];
         
          if(Cdata["docTitle"]){
            this.pageTitle=Cdata["docTitle"];
          }
           if(Cdata["docContent"]){
            this.docContent=Cdata["docContent"];
          } 
          if(Cdata["docTitle_Kn"]){
            
            this.pageTitle_Kn=Cdata["docTitle_Kn"];
          }
  
          if(Cdata["docContent_Kn"]){
            this.docContent_Kn=Cdata["docContent_Kn"];
          }
          if(Cdata["TabContent"]){
            this.tabContents=Cdata["TabContent"];
            if(Cdata["TabContent"].length!=0){
             
            };
           
          }
          
        }
      }
    
      
    });
    
    this.rd.PLUpdate.subscribe(datas => { 
      if (datas) { 
        if (datas["data"]) {
          this.bgColor = datas["data"]["navbg"];
          if (datas["data"]["title"]) {
            this.Title = datas["data"]["title"];
          }
          if (datas["data"]["mobtitle"]) {
            this.Mobtitle = datas["data"]["mobtitle"];
          }
          if (datas["data"]["titlecolor"]) {
            this.titlColor = datas["data"]["titlecolor"];
          }
          
          if (datas["data"]["ISBaba"]) {

            this.IsBaba = (datas["data"]["ISBaba"] == "1") ? true : false;

          }

        }

      }
    });
    

     
  }
  activeTabCall(tab){
    
    window.dispatchEvent( new CustomEvent('tabclicked', {
      bubbles: true,
      detail: tab
    }));
  }

}
