/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng2-pdf-viewer/ng2-pdf-viewer.ngfactory";
import * as i3 from "ng2-pdf-viewer";
import * as i4 from "./privacy.component";
var styles_PrivacyComponent = [i0.styles];
var RenderType_PrivacyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivacyComponent, data: {} });
export { RenderType_PrivacyComponent as RenderType_PrivacyComponent };
export function View_PrivacyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pdf_v"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "pdf-viewer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onPageResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PdfViewerComponent_0, i2.RenderType_PdfViewerComponent)), i1.ɵdid(2, 9158656, null, 0, i3.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], originalSize: [1, "originalSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pdfSource; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_PrivacyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-privacy", [], null, null, null, View_PrivacyComponent_0, RenderType_PrivacyComponent)), i1.ɵdid(1, 114688, null, 0, i4.PrivacyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyComponentNgFactory = i1.ɵccf("app-privacy", i4.PrivacyComponent, View_PrivacyComponent_Host_0, {}, {}, []);
export { PrivacyComponentNgFactory as PrivacyComponentNgFactory };
